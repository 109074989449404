import * as React from "react"
import { graphql, Link } from 'gatsby';
//import LayoutOLD, {MainHeader, MainBody, DivTable, DivRow, DivCol} from "../../components/layout";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { makeRichText } from "../../components/richtext";
import Layout from '../../components/layout'
import defaultData from "../../data/defaults.json";
import {GatsbyImage, getImage, Img, StaticImage} from "gatsby-plugin-image"
import { useContentfulImage } from "gatsby-source-contentful/hooks"
import {slugProject} from "../../components/slug";
import {DefaultHero} from "../../components/static-images";
//import RenderContentfulImage, {IMAGE_TYPE} from "../../components/renderContentfulImage";
import {
    ProjectImage,
    BottomHeroDivHeroImage,
    HeroBox,
    HeroImageBox,
    InfoBox,
    AddressBox,
    MainLogoBox,
    MainLogoImage,
    MainLogoSVG,
    DivBox,
    BottomHeroDiv
} from "../mylib/page-constants";


import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

//import { styled } from '@mui/material/styles';
import {Card, CardContent, CardMedia, Typography, Box, CardHeader,CardActionArea, Avatar, Icon, SvgIcon} from "@mui/material";
import CompanyInfoTab from '../../components/companyInfoTab'
import {red} from "@mui/material/colors";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export const Head = ({ location, params, data, pageContext }) => (
    <>
        <title>{pageContext.title}</title>
        <meta name="description" content={pageContext.title} />
        <meta
            name="twitter:url"
            content={`https://www.foobar.tld/${location.pathname}`}
        />
    </>
)

export const IMAGE_TYPE = {
    HERO: 'heroImage',
    MAINLOGO: 'mainLogoImage'
}

function renderImage(node, classType) {
    if (node == null) {
        return DefaultHero();
    }
    let file = node.file;
    let svg = node.svg;
    let gatsbyImageData = node.gatsbyImageData;
    let alt = node.name;

    if ((file != null) && file.contentType === 'image/svg+xml') {
        if (svg && svg.content) {
            // Inlined SVGs

            return (
    //{DOMPurify.sanitize(svg.content)}
         <Typography
              sx={{ width: 100, height: 100 }}
             dangerouslySetInnerHTML={{
                      __html:  svg.content   }}
            />
            )
        }

        // SVGs that can/should not be inlined
        if (classType == IMAGE_TYPE.HERO) {
            return  <GatsbyImage image={getImage(gatsbyImageData)} alt={ alt } /> }
        else {
            return <GatsbyImage image={getImage(gatsbyImageData)} alt={ alt } /> }

        //return <img src={file.url} alt={alt} />
    }

    // Non SVG images
    if (classType == IMAGE_TYPE.HERO) {
        return  <GatsbyImage image={getImage(gatsbyImageData)} alt={ alt } /> }
    else {
        return <GatsbyImage image={getImage(gatsbyImageData)} alt={ alt } /> }

    //return <GatsbyImage image={gatsbyImageData} alt={alt}/>
}





function makeProjects(item) {
    let image = renderImage(item.logoSquareWhite, IMAGE_TYPE.MAINLOGO);
    return(
        <grid item  >
                <Card sx={{ width: 250}}  >
                    <CardActionArea>
                    <CardHeader
                        title={item.name}
                        subheader= { item.shortDescription } />
                    <CardMedia sx={{ display:'flex', justifyContent:'center' }}>
                        {image}
                    </CardMedia>
                    <CardContent>

                    </CardContent>
                        </CardActionArea>
                </Card>
        </grid>
    )
}

function iterateProjects(node) {
    if ((node == null) || node.length == 0) {
        return null
    }
    return(
    <Grid item >
        <Item>
            <h2>Projects</h2>
            <Grid container={true}>
            {  node.map((item) => makeProjects(item)) }
            </Grid>
        </Item>
    </Grid>
    )
}

function makeListLink(item) {
    return (
        <Grid item>
            <Item>
                <Link to={ item['list']['gatsbyPath'] }> { item['list']['name'] }  </Link>
            </Item>
        </Grid>
    )
}

function iterateLists(node) {
    if ((node == null) || node.length == 0) {
        return (<span>No Lists</span>)
    }

    return(
        <Grid container={true} xs={12}>
            { node.map((item) => makeListLink(item)) }
        </Grid>
    )
}


const DHero =
    <StaticImage src="https://images.ctfassets.net/33v1hcyioehd/1kAQ5LjaPM8iPPgMqGqQgl/69968a31fe2987778b159774a38fa48f/hero.jpeg" alt="hero"/>

function getHero(imgr) {
    if (imgr == null) {
        return (
            <CardMedia
                style={{height: 300, borderRadius: 10}}
                image="https://images.ctfassets.net/33v1hcyioehd/1kAQ5LjaPM8iPPgMqGqQgl/69968a31fe2987778b159774a38fa48f/hero.jpeg"
            />)
    } else {
        return(<CardMedia><GatsbyImage image={getImage(imgr)} alt={'hero'} style={{height: 300, borderRadius: 10}}/></CardMedia>)
    }
}




export default function contentfulCompany(props) {

    return (
            <Layout>
            <Grid container={true} spacing={2} justifyContent={'space-between'}  >
                <Grid item xs={12}  key={"g-2"} id={"hero-image"} style={{width:'100%', height: 300 }}>
                        { getHero(props.data.contentfulCompany.hero) }
                            <Card sx={{ width: 150, height: 150, position: 'absolute', top: 250, left:40, padding: 1 }}>
                                <CardMedia >
                                    { renderImage( props.data.contentfulCompany.logoSquareWhite, IMAGE_TYPE.MAINLOGO) }
                                </CardMedia>
                            </Card>
                </Grid>
                <Grid item xs={12} >
                    <Item>
                        <br/><br/>
                        <h1 className='headerText' >{ props.data.contentfulCompany.name}</h1>
                        <div>{ iterateLists(props.data.contentfulCompany.review) }</div>
                    </Item>
                </Grid>
                <Grid item xs={12} md={7} >
                    <Item>
                        <CompanyInfoTab company={props.data.contentfulCompany}/>
                    </Item>
                </Grid>
                { iterateProjects(props.data.contentfulCompany.project) }
            </Grid>
        </Layout>

    )
}
//         gatsbyPath(filePath: "/projects/{contentfulProject.slug}")
export const query = graphql`
 query Company($slug: String) {
    contentfulCompany(slug: {eq: $slug}) {
      id
      slug
      gatsbyPath(filePath: "/organizations/{contentfulCompany.slug}")
      name
      companyType
      shortDescription
      description {
        raw
      }
        project {
              name
              shortDescription
              gatsbyPath(filePath: "/projects/{contentfulProject.slug}")
                    logoSquareWhite {
                        gatsbyImageData(width:300)
                        title
                        file {
                          contentType
                        }
                        svg {
                          content
                          originalContent
                          dataURI
                          absolutePath
                          relativePath
                        } 
                    }
            }
      addresses {
         title
         street1
         city
         state
         phoneNumber
         }
      digitalProperties {
          title
          url
          }

      hero {
           gatsbyImageData(layout: FULL_WIDTH)
           title
           }
      review {
            list {
              name
              gatsbyPath(filePath: "/lists/{contentfulList.slug}")
              }
            }
      logoSquareWhite {
            gatsbyImageData(width:300)
            title
            file {
              contentType
            }
            svg {
              content
              originalContent
              dataURI
              absolutePath
              relativePath
            } 
            }
      externalContent {
              title
              url
              subTitle
              description {
                raw
              }
              externalContentType {
                code
                logoSquareWhite {
                  gatsbyImageData
                }
                organization
                title
              }
            }
      }
    }
  
`
