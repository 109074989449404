import * as React from "react";
import TabContext from "@mui/lab/TabContext";
import {Avatar, Box, Card, CardActionArea, CardContent, CardHeader, CardMedia} from "@mui/material";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import {makeRichText} from "./richtext";
import Grid from "@mui/material/Grid";
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import {GatsbyImage, getImage} from "gatsby-plugin-image";

function CompanyInfoTab(props) {
    const [value, setValue] = React.useState('1');

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    function makeDigitalProperties(item) {
        return(
            <Grid item>
                <Card sx={{ width: 320, height: 100 }}>
                    <CardContent>
                        <div className="propertyTitle">{item.title}</div>
                        <div className="propertyUrl">{item.url}</div>
                    </CardContent>
                </Card>
            </Grid>
        )
    }

    function iterateDigitalProperties(properties) {
        if ((properties == null) || properties.length == 0) {
            return null;
        }
        return(
            <Grid container={true} item  >
                    { properties.map((item) => makeDigitalProperties(item)) }
            </Grid>
        )
    }


    function makeAddress(item) {
        return(
            <Grid item>
                    <Card sx={{ width: 320, height: 200 }}>
                        <CardContent>
                            <div className="vcard">
                                {'title' in item && <div className="fn org"><h3>{item.title}</h3></div> }
                                <div className="adr">
                                    {'street1' in item && <div className="street-address">{item.street1}</div> }
                                    {'street2' in item && <div className="extended-address">{item.street2}</div> }
                                    {'city' in item && <div className="locality">{item.city}</div> }
                                    {'state' in item && <div className="region">{item.state}</div> }
                                    {'country' in item && <div className="country-name">{item.country}</div> }
                                    {'postalCode' in item && <div className="postal-code">{item.postalCode}</div> }
                                </div>
                                {'phoneNumber' in item &&
                                    <div className="tel">
                                        {item.phoneNumber}
                                    </div>
                                }
                            </div>
                        </CardContent>
                    </Card>
            </Grid>
        );
    }


    function makeExternalContent(externalContent) {
        return(
            <grid item  >
                <Card sx={{ width: 175}}  >
                    <CardActionArea>
                        <Avatar>
                             <GatsbyImage image={getImage(externalContent.externalContentType.logoSquareWhite.gatsbyImageData)} alt={  externalContent.externalContentType.title } />
                        </Avatar>
                        <CardHeader
                            title={externalContent.title}
                            subheader= { externalContent.subTitle } />
                        <CardContent>
                            {makeRichText(externalContent.description)}
                        </CardContent>
                    </CardActionArea>
                </Card>
            </grid>
        );
    }



    function iterateAddresses(addresses) {
        if ((props.company.addresses == null) || (props.company.addresses.length == 0)) {
            return (<p>No Locations Listed</p>);
        }
        return (
            <Grid container={true} item  >
                        { addresses.map((item) => makeAddress(item)) }
            </Grid>
        )
    }

    function iterateExternalContent(externalContent) {
        if ((externalContent == null) || (externalContent.length == 0)) {
            return (<p>No External Content Listed</p>);
        }
        return (
            <Grid container={true} item  >
                { externalContent.map((item) => makeExternalContent(item)) }
            </Grid>
        )
    }


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <TabContext value={value}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                    <Tab label="Information" value="1"/>
                    <Tab label="Addresses" value="2"/>
                    <Tab label="Websites" value="3"/>
                    <Tab label="External" value="4"/>
                </TabList>
            </Box>
            <TabPanel value="1">
                <h2>About {props.company.name}</h2>
                <h3>Company Information</h3>
                Type
                {props.company.companyType}

                Description
                {makeRichText(props.company.description)}
            </TabPanel>
            <TabPanel value="2">
                { iterateAddresses(props.company.addresses) }
            </TabPanel>
            <TabPanel value="3">
                { iterateDigitalProperties(props.company.digitalProperties)}
            </TabPanel>
            <TabPanel value="4">
                { iterateExternalContent(props.company.externalContent)}
            </TabPanel>
        </TabContext>
    )
}

export default CompanyInfoTab